.container {
	height: 100%;
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	align-items: center;
	gap: 3rem;
	
	.link {
		padding: 0.5rem;
		list-style: none;
		font-size: 1.8rem;
		color: var(--color-white);
	}
}

