.button {
  $height: 3.2rem;

  height: $height;
  padding: 0;

  .icon {
    svg {
      height: $height;
      width: auto;

      path {
        fill: var(--color-white);
      }
    }
  }
}

.drawer {
  background: var(--color-accent) !important;

  [class*="-drawer-close"] {
    svg {
      path {
        fill: var(--color-white);
      }
    }
  }

  .links {
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: max-content;
    align-items: center;
    gap: 3rem;

    .link {
      padding: 0.5rem;
      list-style: none;
      font-size: 1.8rem;
      color: var(--color-white);
    }
  }
}

