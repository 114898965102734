.container {
	display: grid;
	grid-template-areas:
			'title'
			'image'
			'content';
	gap: 1rem;
	
	.title {
		grid-area: title;
		font-size: var(--font-size-h2);
		
		a {
			color: var(--color-accent);
			
			&:hover {
				text-decoration: underline;
			}
		}
	}
	
	.image {
		grid-area: image;
		border-radius: 50%;
		justify-self: center;
		max-width: 24rem;
		height: auto;
	}
	
	.contentContainer {
		grid-area: content;
	}
}

@media (width >= 992px) {
	.container {
		grid-template-areas:
    'title title'
    'image content';
		gap: 2rem;
		
		.image {
			max-width: 14rem;
			position: sticky;
			top: 1rem;
		}
	}
}
