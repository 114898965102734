.container {
	&.select {
		height: 100% !important;
		
		[class*='-select-selector'] {
			background: unset !important;
			border: none !important;
		}
		
		[class*='-select-selection-item'] {
			display: inline-flex;
			align-self: center;
			justify-self: center;
		}
	}
	
	[class*='-select-item'] {
		padding: 0 !important;
		align-self: center;
		
		&:not(:first-child) {
			margin-top: 1rem;
		}
	}
	
	// NOTE: flag image
	img {
		height: 3rem;
		width: auto;
		border: 1px solid #000;
	}
	
	.link {
		display: flex;
	}
}
