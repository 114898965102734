.container {
	h1, h2, h3, h4, h5 {
		font-weight: 500;
		margin-block: 3rem;
	}
	
	h1 span {
		font-size: var(--font-size-h1);
	}
	
	h2 span {
		font-size: var(--font-size-h2);
	}
	
	h3 span {
		font-size: var(--font-size-h3);
	}
	
	h4 span {
		font-size: var(--font-size-h4);
	}
	
	h5 span {
		font-size: var(--font-size-h5);
	}
	
	span {
		font-size: var(--font-size-span);
	}
	
	h1 {
		font-size: var(--font-size-h1);
	}
	
	h2 {
		font-size: var(--font-size-h2);
	}
	
	h3 {
		font-size: var(--font-size-h3);
	}
	
	h4 {
		font-size: var(--font-size-h4);
	}
	
	h5 {
		font-size: var(--font-size-h5);
	}
	
	
	ul {
		margin-bottom: 2rem;
		margin-top: 0;
	}
	
	li {
		list-style: none;
		position: relative;
		margin-left: 4rem;
		display: grid;

		&:not(:last-child) {
			margin-bottom: 1rem;
		}
		
		&::before {
			content: '•';
			position: absolute;
			transform: translateY(-50%);
			top: 50%;
			font-size: 40px;
			color: var(--color-accent-2);
			display: inline-block;
			margin: 0 0.4rem 0 -3rem;
			line-height: inherit;
		}
	}
	
	p {
		font-size: var(--font-size-p);
		margin-bottom: 1rem;
		margin-top: 0;
	}

	li {
		font-size: var(--font-size-li);
		margin-bottom: 1rem;
		margin-top: 0;
	}
	
	span {
		font-size: var(--font-size-span);
	}
	
	img {
		width: 100%;
	}
	
	a {
		color: var(--color-accent);
		
		&:hover {
			text-decoration: underline;
		}
	}
}
