.container {
  height: var(--page-footer-height);
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-bottom: var(--banner-max-height--collapsed);
  color: var(--font-color--primary);
  background: var(--background-color--primary);
}

@media (width >= 992px) {
  .container {
    margin-bottom: unset;
  }
}
