.container {
  height: var(--page-header-height);
  display: grid;
  grid-template-areas: 'logo . lang nav';
  grid-template-columns: max-content 1fr max-content max-content;
  align-items: center;
  gap: 2rem;
  padding-inline: 2rem;
  
  &:has(.download) {
    grid-template-areas: 'logo . download lang nav';
    grid-template-columns: max-content 1fr max-content max-content max-content;
  }

  .logo {
    grid-area: logo;
    display: flex;
    align-self: center;
    height: calc(var(--page-header-height) * 0.6);
    aspect-ratio: 1 / 1;
    width: auto;
  }

  .navigation {
    grid-area: nav;
    justify-self: flex-end;
  }

  .download {
    grid-area: download;
    justify-self: flex-end;
  }

  .languagePicker {
    grid-area: lang;
    justify-self: center;
  }
}

@media (width >= 992px) {
  .container {
    grid-template-areas: 'logo nav lang';
    grid-template-columns: max-content 1fr max-content;
    gap: 4rem;
    
    &:has(.download) {
      grid-template-areas: 'logo nav download lang';
      grid-template-columns: max-content 1fr max-content max-content;
    }

    .navigation {
      justify-self: flex-start;
    }
  }
}
