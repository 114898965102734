.container {
  $margin-inline: 1rem;

  position: fixed;
  bottom: 0;
  left: $margin-inline;
  width: calc(100% - $margin-inline * 2);
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  background: var(--background-color--primary);
  border-radius: 1rem 1rem 0 0 ;
  box-shadow: 0 0.8rem 1rem -0.5rem rgba(0, 0, 0, 0.14), 0 1.6rem 2.4rem 0.2rem rgba(0, 0, 0, 0.1), 0 0.6rem 3rem 0.5rem rgba(0, 0, 0, 0.08);
  z-index: 1;
}

@media (width >= 992px) {
  .container {
    position: sticky;
    top: var(--page-content-padding);
    width: 36rem;
    height: max-content;
    border-radius: 1rem;
  }
}
