.container {
	position: relative;
	height: 100dvh;
	display: grid;
	grid-auto-flow: row;
	grid-template-rows: max-content 1fr;
	
	.header {
		background: var(--color-accent);
		position: relative;
		
		&Content {
			max-width: var(--page-content-max-width);
			margin-inline: auto;
			padding-inline: var(--page-content-padding);
		}
	}
	
	.body {
		display: grid;
		grid-template-rows: 1fr max-content;
		overflow: auto;
		position: relative;
		
		&Content {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr max-content;
			max-width: var(--page-content-max-width);
			margin-inline: auto;
			padding: var(--page-content-padding);
		}
		
		
		.footer {
			
			&Content {
				max-width: var(--page-content-max-width);
				margin-inline: auto;
				padding-inline: var(--page-content-padding);
			}
		}
	}
}

@media (width >= 992px) {
	.container {
		.body {
			
			&Content {
				gap: var(--page-content-padding);
			}
		}
	}
}
