.container {
  .expandButton {
    width: max-content;
    align-self: center;
    justify-self: center;
    transform: rotate(-90deg);
    padding: 0.4rem;
  }
  
  .links {
    list-style-type: none;
    display: grid;
    gap: 1rem;

    .link {

      .linkButton {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--color-accent);
        font-size: var(--font-size-h5);
        width: 100%;
        height: var(--banner-button-height);
      }
    }
  }
}
