.container {
  max-height: var(--banner-max-height--collapsed);
  transition: max-height 300ms ease;

  &.isOpen {
    max-height: var(--banner-max-height--expanded);

    .expandButton {
      transform: rotate(-270deg);
    }
  }

  .expandButton {
    display: flex;
    align-items: center;
    width: max-content;
    align-self: center;
    justify-self: center;
    padding: 0.4rem;
    transform: rotate(-90deg);
    transition: transform 300ms ease;
  }

  .links {
    display: grid;
    gap: 1rem;
    max-height: max-content;
    overflow: auto;
    transition: max-height 300ms ease;
    list-style-type: none;

    .link {

      .linkButton {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--color-accent);
        font-size: var(--font-size-h5);
        width: 100%;
        height: var(--banner-button-height);
      }
    }
  }
}
